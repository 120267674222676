<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Submission Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <div class="grid grid-cols-2 gap-12 mb-4">
          <base-input
            v-model="submissionForm.contractNumber"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Contract Number"
            :error="submissionFormValidations.upcOptOutComments"
            :disabled="[VIEW_ONLY, REQUIRED].includes(contractNumberFieldState) && !submissionForm.isAdminEdit"
            :is-required="contractNumberFieldState === REQUIRED"
          />
          <div class="flex justify-between items-center">
            <div class="flex items-center h-7">
              <base-button
                class="mr-5 flex items-center"
                variant="btn-primary"
                size="py-1.5 px-2.5"
                :text="`${['DRAFT','NEW'].includes(submissionFormMode) ? 'Change':'Update'} Contract`"
                type="button"
                @click="setContractModalVisibility(true)"
              />
              <base-button
                variant="btn-link"
                text="Deal Summary"
                tag="div"
                @click="openDealSummary()"
              />
            </div>
            <base-button
              v-if="downloadPressReleaseTemplateButtonState !== INVISIBLE"
              variant="btn-link"
              text="Download Press Release Template"
              tag="div"
              @click="downloadPressReleaseTemplate()"
            />
          </div>
        </div>
        <base-input
          v-if="contractStatusFieldState !== INVISIBLE"
          v-model="submissionForm.contractStatus"
          type="text"
          :title="submissionForm.contractStatus ==='DRAFT' ? 'The contract on this submission is in DRAFT status. You will be allowed to submit for Pre-Production stage only on a booked contract. Please contact your deal originator.' : 'Contract Status'"
          :style="submissionForm.contractStatus==='DRAFT' ? 'color:red' : ''"
          container-class="w-full mb-4 flex items-center"
          label-class="label-w-46"
          label="Contract Status"
          :disabled="contractStatusFieldState === VIEW_ONLY"
        />
        <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-input
            v-model="submissionForm.submissionTypeName"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Submission Type"
            disabled
          />
          <base-input
            :model-value="submissionDate"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Submission Date"
            disabled
          />
          <base-input
            v-model="submissionForm.submissionId"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Submission ID"
            disabled
          />
          <base-input
            v-if="legacySubmissionIdFieldState !== INVISIBLE"
            v-model="submissionForm.legacySubmissionId"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Legacy Submission ID"
            disabled
          />
        </div>
        <base-input
          v-if="opaNumberFieldState !== INVISIBLE"
          v-model="submissionForm.opaId"
          type="text"
          container-class="flex items-center mb-4"
          label-class="label-w-46"
          label="OPA#"
          :error="submissionFormValidations.opaId"
          :is-required="opaNumberFieldState === REQUIRED"
        />
        <div class="grid grid-cols-2 gap-x-4 gap-y-4 mb-4">
          <base-input
            type="text"
            :value="submissionForm.shortArticleDescription.replaceAll(` - ${__shortArticleDesc.toUpperCase()}`,'')"
            container-class="w-full flex"
            label-class="label-w-46"
            is-required
            :label="submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE ? 'Press Release Title' : 'Title / Short Article Description'"
            disabled
          />
          <div class="flex w-full">
            <span class="pr-4 text-lg"> - </span>
            <base-input
              v-model="__shortArticleDesc"
              type="text"
              container-class="w-full flex"
              label-class="label-w-46"
              :is-required="submissionForm?.shortArticleDescription?.length > 256 ? true : false"
              :error-text="submissionForm?.shortArticleDescription?.length > 256 ? 'Only 256 Characters are allowed.' : ''"
              :error="submissionFormValidations?.shortArticleDescription || submissionForm?.shortArticleDescription.length > 256"
              @blur="upperCaseInputDescriptions(__shortArticleDesc)"
            />
          </div>
        </div>
        <base-input
          v-model="submissionForm.longArticleDescription"
          text-area
          rows="3"
          container-class="w-full mb-4 flex"
          label-class="label-w-46"
          :label="submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE ? 'Press Release Description' : 'Long Article Description'"
          :error="submissionFormValidations.longArticleDescription || submissionForm.longArticleDescription.length > 1024"
          :is-required="submissionForm.longArticleDescription.length > 1024 ? true : false"
          :error-text="submissionForm.longArticleDescription.length > 1024 ? 'Only 1024 Characters are allowed.' : ''"
          @blur="upperCaseInputDescriptions(submissionForm.longArticleDescription)"
        />
        <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-select
            v-if="gameCodeFieldState !== INVISIBLE && showGameCodeDropDown === 'N'"
            v-model="submissionForm.gameCode"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Game Code"
            label-class="label-w-46"
            :is-required="gameCodeFieldState === REQUIRED"
            :error="submissionFormValidations.gameCode"
            :options="getDistinctArray(gameCodeOptions)"
            show-default-option
            @dropdownOpened="fetchGameCodes()"
          />
          <base-input
            v-if="gameCodeFieldState !== INVISIBLE && showGameCodeDropDown === 'Y'"
            v-model="submissionForm.gameCode"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Game Code"
            :error="submissionFormValidations.gameCode"
            :is-required="gameCodeFieldState === REQUIRED"
          />
          <base-checkbox
            v-if="gameCodeFieldState !== INVISIBLE"
            v-model="submissionForm.gameCodeChecked"
            container-class="flex items-center mr-5"
            value="Y"
            unchecked-value="N"
            @update:modelValue="handlGameCodeSelection($event)"
          >
            <template #label>
              <label class="ml-2 text-black text-sm italic">
                New Game Code Value
              </label>
            </template>
          </base-checkbox>
          <base-select
            v-if="assetTypeFieldState !== INVISIBLE"
            v-model="submissionForm.assetType"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Asset Type"
            label-class="label-w-46"
            :is-required="assetTypeFieldState === REQUIRED"
            :error="submissionFormValidations.assetType"
            :options="getDistinctArray(assetTypeOptions)"
            show-default-option
            @dropdownOpened="fetchDropDownOptions('assetTypeOptions')"
          />
          <base-select
            v-if="categoryFieldState !== INVISIBLE"
            v-model="submissionForm.category"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Category"
            label-class="label-w-46"
            :is-required="categoryFieldState === REQUIRED"
            :error="submissionFormValidations.category"
            :options="getDistinctArray(categoryOptions)"
            show-default-option
            @dropdownOpened="fetchArticlesCategory('categoryOptions')"
          />
          <base-select
            v-if="articleFieldState !== INVISIBLE"
            v-model="submissionForm.articleName"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Article"
            label-class="label-w-46"
            :is-required="articleFieldState === REQUIRED"
            :error="submissionFormValidations.article"
            :options="submissionForm.category ? getDistinctArray(articleOptions) : ['']"
            show-default-option
          />
          <base-select
            v-if="articleNotesFieldState !== INVISIBLE"
            v-model="submissionForm.articleNotes"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Article Notes"
            label-class="label-w-46"
            :disabled="
              !articleNotesOptions
                || !articleNotesOptions.length
                || (articleNotesFieldState === VIEW_ONLY && !submissionForm.isAdminEdit)
            "
            :is-required="articleNotesFieldState === REQUIRED"
            :options="getDistinctArray(articleNotesOptions)"
            show-default-option
          />
          <base-select
            v-if="pressReleaseCategoryFieldState !== INVISIBLE"
            v-model="submissionForm.pressReleaseCategory"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Press Release Category"
            label-class="label-w-46"
            :is-required="pressReleaseCategoryFieldState === REQUIRED"
            :error="submissionFormValidations.pressReleaseCategory"
            :options="getDistinctArray(pressReleaseCategoryOptions)"
            show-default-option
            @dropdownOpened="fetchDropDownOptions('pressReleaseCategoryOptions')"
          />
          <base-select
            v-if="materialSuppliedFieldState !== INVISIBLE"
            v-model="submissionForm.materialsSupplied"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Material Supplied"
            label-class="label-w-46"
            :is-required="materialSuppliedFieldState === REQUIRED"
            :disabled="materialSuppliedFieldState === VIEW_ONLY"
            :error="submissionFormValidations.materialsSupplied"
            :options="getDistinctArray(materialSuppliedOptions)"
            show-default-option
            @dropdownOpened="fetchDropDownOptions('materialSuppliedOptions')"
          />
          <base-select
            v-if="safetyDocFieldState !== INVISIBLE"
            v-model="submissionForm.safetyDocIncluded"
            class="flex text-sm text-left"
            :placeholder="safetyDocFieldState === VIEW_ONLY ? '' : '-- SELECT --'"
            label="Safety Documents Included"
            label-class="label-w-46"
            :disabled="safetyDocFieldState === VIEW_ONLY && !submissionForm.isAdminEdit"  
            :is-required="safetyDocFieldState === REQUIRED"
            :error="submissionFormValidations.safetyDocIncluded"
            :options="getDistinctArray(safetyDocumentsIncludedOptions)"
            show-default-option
          />
          <base-select
            v-if="sampleIncludedFieldState !== INVISIBLE"
            v-model="submissionForm.physicalSampleIncluded"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Sample Included"
            label-class="label-w-46"
            :error="submissionFormValidations.physicalSampleIncluded"
            :error-text= "sendSampleMessage"     
            :is-required="sampleIncludedFieldState === REQUIRED"
            :options="getDistinctArray(sampleIncludedOptions)"
            :disabled="sampleIncludedOptions.length && sampleIncludedOptions.length == 1 && !submissionForm.isAdminEdit"
            show-default-option
          />
          <base-select
            v-if="genLineOfBusinessFieldState !== INVISIBLE"
            v-model="submissionForm.genLob"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Line of Business"
            label-class="label-w-46"
            :is-required="genLineOfBusinessFieldState === REQUIRED"
            :error="submissionFormValidations.genLob"
            :options="getDistinctArray(lineOfBusinessOptions)"
            show-default-option
            @dropdownOpened="fetchDropDownOptions('lineOfBusinessOptions')"
          />
          <base-select
            v-if="genCategoryFieldState !== INVISIBLE"
            v-model="submissionForm.genCategory"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Generic Category"
            label-class="label-w-46"
            :is-required="genCategoryFieldState === REQUIRED"
            :disabled="!submissionForm.genLob && !submissionForm.isAdminEdit"
            :error="submissionFormValidations.genCategory"
            :options="getDistinctArray(genericCategoryOptions)"
            show-default-option
            @dropdownOpened="fetchDropDownOptions('genericCategoryOptions')"
          />
          <base-select
            v-if="genTypeFieldState !== INVISIBLE"
            v-model="submissionForm.genType"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Type"
            label-class="label-w-46"
            :is-required="genTypeFieldState === REQUIRED"
            :disabled="!submissionForm.genLob"
            :error="submissionFormValidations.genType"
            :options="getDistinctArray(typeOptions)"
            show-default-option
            @dropdownOpened="fetchDropDownOptions('typeOptions')"
          />
          <base-select
            v-if="submittedItemForReviewFieldState !== INVISIBLE"
            v-model="submissionForm.licenseeReqStage"
            title="Note this field is solely intended to provide the most accurate description of the item being submitted for review. It does not mean you are permitted to skip any required stages nor does this field waive any requirements under your licensee agreement."
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Submitted Item For Review"
            label-class="label-w-46"
            :is-required="submittedItemForReviewFieldState === REQUIRED"
            :error="submissionFormValidations.licenseeReqStage"
            :options="getDistinctArray(itemForReviewOptions)"
            show-default-option
            @dropdownOpened="fetchItemForReview()"
          />
          <base-select
            v-if="packagingSubmissionNumberFieldState !== INVISIBLE"
            v-model="submissionForm.packingReferenceNo"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Packaging Submission Number"
            label-class="label-w-46"
            :options="packagingSubmissionNumberOptions?.length > 0 ? getDistinctArray(packagingSubmissionNumberOptions) : ['']"
            show-default-option
            @dropdownOpened="fetchPackagingSubmissionNumber()"
          />
          <base-date-picker
            v-if="onShelfDateFieldState !== INVISIBLE"
            v-model="submissionForm.onShelfDate"
            mode="date"
            container-class="w-full flex text-sm"
            label-class="label-w-46"
            label="On Shelf Date"
            :is-required="onShelfDateFieldState === REQUIRED"
            :error="submissionFormValidations.onShelfDate"
          />

          <base-input
            v-if="podArtReferenceNumberFieldState !== INVISIBLE"
            v-model="submissionForm.podArtReferenceNumber"
            type="text"
            container-class="flex items-center"
            label="POD Art Reference Number"
            :is-required="podArtReferenceNumberFieldState === REQUIRED"
            :error="submissionFormValidations.podArtReferenceNumber"
          />

          <base-select
            v-if="finalInventoryFieldState !== INVISIBLE"
            v-model="submissionForm.finalInventory"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Final Inventory"
            label-class="label-w-46"
            :is-required="finalInventoryFieldState === REQUIRED"
            :error="submissionFormValidations.finalInventory"
            :options="getDistinctArray(finalInventoryOptions)"
            show-default-option
          />
        </div>
      </div>
      <!-- change contract modal -->
      <change-contract-modal
        v-model="contractModalVisibility"
        @hide="setContractModalVisibility(false)"
      />
    </template>
  </collapse-card>
  <!-- deal summary modal -->
  <brand-assurance-deal-summary-modal
    v-model="isDealSummaryModalVisible"
    @hide="setDealSummaryModalVisibility(false)"
  />
</template>

<script>
import { defineAsyncComponent, ref, computed, reactive, watch, onMounted } from 'vue';
import useSubmissionForm from './submissionForm.js';
import { useStore } from 'vuex';
import useDealSummary from '@/components/brand-assurance-deal-summary/dealSummary';
import { formatDate, getDistinctArray } from '@/helpers/util';
import {
    OPA_CODE_PARAM_KEY,
    POD_CODE_PARAM_KEY,
    GEN_TYPE_PARAM_KEY,
    CAT_TYPE_PARAM_KEY,
    OPA_SUBMISSION_TYPE,
    ASSET_TYPE_PARAM_KEY,
    GEN_CAT_TYPE_PARAM_KEY,
    GEN_LOB_TYPE_PARAM_KEY,
    ASSET_TYPE_DROPDOWN_KEY,
    GENERIC_SUBMISSION_TYPE,
    MATERIALS_TYPE_PARAM_KEY,
    STANDARD_SUBMISSION_TYPE,
    TYPE_OPTIONS_DROPDOWN_KEY,
    PRESS_RELEASE_DROPDOWN_KEY,
    INTERACTIVE_SUBMISSION_TYPE,
    STD_MATERIALS_CODE_PARAM_KEY,
    INT_MATERIALS_CODE_PARAM_KEY,
    GEN_MATERIALS_CODE_PARAM_KEY,
    LINE_OF_BUSINESS_DROPDOWN_KEY,
    GENERIC_CATEGORY_DROPDOWN_KEY,
    PRESS_CATEGORY_CODE_PARAM_KEY,
    PRESS_RELEASE_SUBMISSION_TYPE,
    PRINT_ON_DEMAND_SUBMISSION_TYPE,
    LICENSEE_STEP,
    PRODUCTION_STAGE,
    CONTRACTUAL_STAGE,
    ANNUALS_STAGE,
    PRE_PRODUCTION_STAGE,
    CONCEPT_STAGE,
    REQUIRED,
    EDITABLE,
    VIEW_ONLY,
    FINAL_STAGE,
    INVISIBLE,
    ACTIVE_BUTTON,
    CATEGORY_DROPDOWN_KEY,
    MATERIAL_SUPPLIED_DROPDOWN_KEY,
    REVISED_STAGE,
    DIGITAL_GOOD_METARIAL,
    PRINT_ON_DEMAND_SAMPLE_METARIAL,
    PREMIUM_GWPS_PRODUCTS_METARIAL
} from '@/constants/submissions.js';
import { REQUIRED_FIELD, SELECT_LINE_OF_BUSINESS } from '@/constants/validationMessages.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ATTACHEMENT_DOWNLOADED_SUCCESSFULLY, ERROR, SUCCESS, WARNING } from '@/constants/alerts.js';

export default {
    name: 'SubmissionDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        ChangeContractModal: defineAsyncComponent(() => import('./ChangeContractModal.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseDatePicker: defineAsyncComponent(() => import('@/components/generic-components/BaseDatePicker.vue')),
        BrandAssuranceDealSummaryModal: defineAsyncComponent(() => import('@/components/brand-assurance-deal-summary/BrandAssuranceDealSummaryModal.vue'))
    },

    inheritAttrs: false,

    setup () {
        const store = useStore();
        const { submissionForm, notifiersEnabled, submissionFormMode, submissionFormValidations } = useSubmissionForm();
        const { notificationsStack } = useToastNotifications();
        const { dealSummaryData } = useDealSummary();

        const upperCaseInputDescriptions = () => {
            __shortArticleDesc.value = __shortArticleDesc.value.toUpperCase();
            submissionForm.longArticleDescription = submissionForm.longArticleDescription.toUpperCase();
        };

        // submission details validations
        const contractNumberFieldState = computed(() => submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE ? REQUIRED : VIEW_ONLY);
        const contractStatusFieldState = computed(() => submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE && [CONCEPT_STAGE, FINAL_STAGE].includes(submissionForm.currentStageName) ? INVISIBLE : VIEW_ONLY);
        const genCategoryFieldState = computed(() => submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE ? REQUIRED : INVISIBLE);
        const genLineOfBusinessFieldState = computed(() => GENERIC_SUBMISSION_TYPE === submissionForm.submissionTypeName ? REQUIRED : INVISIBLE);
        const genTypeFieldState = computed(() => GENERIC_SUBMISSION_TYPE === submissionForm.submissionTypeName ? REQUIRED : INVISIBLE);
        const sendSampleMessage= computed(() => { 
            if([PRE_PRODUCTION_STAGE, PRODUCTION_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)  || !submissionForm.physicalSampleIncluded ){
                return 'Required Field';
            } else if(submissionForm.currentStageName === CONTRACTUAL_STAGE && submissionForm?.licenseeReqStage?.toLowerCase()?.includes('sample') && submissionForm.materialsSupplied != PRINT_ON_DEMAND_SAMPLE_METARIAL) {
                return 'Please send the samples and fill in the details';
            } 
        })
        const articleFieldState = computed(() => {
            if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return INVISIBLE;
            } else if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return REQUIRED;
            } else {
                return EDITABLE;
            }
        });

        const articleNotesFieldState = computed(() => {
            if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return EDITABLE;
            } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                return VIEW_ONLY;
            } else {
                return INVISIBLE;
            }
        });

        const safetyDocFieldState = computed(() => {
            if( submissionForm.currentStepName === LICENSEE_STEP
                     && [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) 
                     && submissionForm.isSafetyNeeded === 'Y'){
                submissionForm.safetyDocIncluded ='Yes';
                return VIEW_ONLY;
            }
            if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if (submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL && (submissionFormMode.value === 'NEW' || submissionForm.currentStepName === LICENSEE_STEP)) return REQUIRED;
                
                if (submissionForm.currentStepName === LICENSEE_STEP) {
                    return [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
                } else {
                    return EDITABLE;
                }
            } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return [CONTRACTUAL_STAGE, PRODUCTION_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
            } else if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        });

        const sampleIncludedFieldState = computed(() => {
            if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if (submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL && (submissionFormMode.value === 'NEW' || submissionForm.currentStepName === LICENSEE_STEP)) {
                    if(submissionForm.enforceSample==='Y' && [CONTRACTUAL_STAGE].includes(submissionForm.currentStageName) && submissionForm.currentStepName === LICENSEE_STEP) {
                        if(!submissionForm.physicalSampleIncluded) {                           
                            submissionFormValidations.physicalSampleIncluded =REQUIRED_FIELD;
                            return REQUIRED;  
                        } else {
                            return EDITABLE;
                        } 
                    } else {
                        return EDITABLE;
                    }
                }  
                
                if (submissionForm.enforceSample==='Y' && [CONTRACTUAL_STAGE].includes(submissionForm.currentStageName) && submissionForm?.licenseeReqStage?.toLowerCase()?.includes('sample') &&  submissionForm.currentStepName === LICENSEE_STEP) { 
                        if(!submissionForm.physicalSampleIncluded) {   
                            submissionFormValidations.physicalSampleIncluded =REQUIRED_FIELD;
                            return REQUIRED;  
                        } else {
                            return REQUIRED;  
                        }
                } 
              
                if( [PRE_PRODUCTION_STAGE, PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) && submissionForm.currentStepName === LICENSEE_STEP) {
                     if(!submissionForm.physicalSampleIncluded) {   
                        if(submissionForm.enforceSample==='Y') { 
                            submissionFormValidations.physicalSampleIncluded =REQUIRED_FIELD;
                            return REQUIRED;  
                        } else {
                            return EDITABLE;
                        }
                    }
                } 
                
            } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                if (submissionForm.materialsSupplied === PREMIUM_GWPS_PRODUCTS_METARIAL ) {
                    if(submissionFormMode.value === 'NEW' || submissionForm.currentStepName === LICENSEE_STEP) {
                        if(!submissionForm.physicalSampleIncluded) { 
                            submissionFormValidations.physicalSampleIncluded =REQUIRED_FIELD;
                            return REQUIRED;  
                        } else {
                            return EDITABLE;  
                        }
                    } 
                }
                if([PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) && submissionForm.currentStepName === LICENSEE_STEP){
                        if(!submissionForm.physicalSampleIncluded){
                            submissionFormValidations.physicalSampleIncluded =REQUIRED_FIELD;
                            return   REQUIRED ;
                        }else{
                            return   EDITABLE;
                        }
                }
               
            } else if ([INTERACTIVE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return EDITABLE;
            } else if (submissionForm.submissionTypeName === OPA_SUBMISSION_TYPE) {
                return (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') ? REQUIRED : EDITABLE;
            } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        });

        const onShelfDateFieldState = computed(() => {
            if (submissionForm.submissionStatus === 'Draft') return INVISIBLE;
            if (STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                if ([DIGITAL_GOOD_METARIAL, PRINT_ON_DEMAND_SAMPLE_METARIAL].includes(submissionForm.materialsSupplied)) {
                    return INVISIBLE;
                } else if ([PRE_PRODUCTION_STAGE, PRODUCTION_STAGE, ANNUALS_STAGE, CONTRACTUAL_STAGE].includes(submissionForm.currentStageName)) {
                    return REQUIRED;
                } else {
                    return EDITABLE;
                }
            } else if (GENERIC_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                if ([PREMIUM_GWPS_PRODUCTS_METARIAL].includes(submissionForm.materialsSupplied)) {
                    if ([PRODUCTION_STAGE, PRE_PRODUCTION_STAGE, CONTRACTUAL_STAGE].includes(submissionForm.currentStageName) && submissionForm.currentStepName === LICENSEE_STEP) {
                        return REQUIRED;
                    } else if ([REVISED_STAGE, FINAL_STAGE].includes(submissionForm.currentStageName)) {
                        return INVISIBLE;
                    } else {
                        return EDITABLE;
                    };
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const podArtReferenceNumberFieldState = computed(() => {
            if (STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                return submissionForm.materialsSupplied === 'Print on Demand Samples' ? REQUIRED : INVISIBLE;
            } else {
                return INVISIBLE;
            }
        });

        const finalInventoryFieldState = computed(() => {
            if (STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                return [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
            } else {
                return INVISIBLE;
            }
        });

        const categoryFieldState = computed(() => {
            if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return REQUIRED;
            } else if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        });

        const materialSuppliedFieldState = computed(() => {
            if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return REQUIRED;
            } else if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE) {
                return (submissionForm.currentStageName === CONCEPT_STAGE || submissionFormMode.value === 'NEW') ? REQUIRED : VIEW_ONLY;
            } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        });

        const packagingSubmissionNumberFieldState = computed(() => STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName ? EDITABLE : INVISIBLE);
        const gameCodeFieldState = computed(() => INTERACTIVE_SUBMISSION_TYPE === submissionForm.submissionTypeName ? REQUIRED : INVISIBLE);
        const assetTypeFieldState = computed(() => INTERACTIVE_SUBMISSION_TYPE === submissionForm.submissionTypeName ? REQUIRED : INVISIBLE);
        const pressReleaseCategoryFieldState = computed(() => PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName ? REQUIRED : INVISIBLE);
        const downloadPressReleaseTemplateButtonState = computed(() => PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName ? ACTIVE_BUTTON : INVISIBLE);
        const opaNumberFieldState = computed(() => OPA_SUBMISSION_TYPE === submissionForm.submissionTypeName ? REQUIRED : INVISIBLE);
        const legacySubmissionIdFieldState = computed(() => submissionForm.legacySubmissionId === null ? INVISIBLE : EDITABLE);

        const submittedItemForReviewFieldState = computed(() => {
            if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE) {
                return REQUIRED;
            } else {
                return INVISIBLE;
            }
        });

        // ------- WATCHERS -------

        watch(
            () => submissionForm.currentStageName,
            () => {
                if ([CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)) {
                    submissionForm.physicalSampleIncluded = 'Yes';
                    if (submissionForm.isSafetyNeeded === 'Y') submissionForm.safetyDocIncluded = 'Yes';
                }
            }
        );

        watch(
            () => submissionForm.physicalSampleIncluded,
            () => {
                if (submissionForm.physicalSampleIncluded === 'Yes') {
                    if (notifiersEnabled.value) {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: 'Please include a printed copy of this submission receipt along with the sample shipment.'
                        });
                    }
                } 
            }
        );

        watch(
            () => submissionForm.safetyDocIncluded,
            () => {
                if (['Yes', 'Y'].includes(submissionForm.safetyDocIncluded)){
                    submissionForm.isSafetyNeeded = 'Y';
                } else if (submissionForm.safetyDocIncluded === 'No') {
                    submissionForm.isSafetyNeeded = 'N';
                }
                
            }
        );

        watch(
            () => submissionForm.category,
            (__new, __old) => {
                // If the notifications are NOT enabled, ignore the watcher since the submission is being switched
                if (notifiersEnabled.value) {
                    if (!__new || __new !== __old) {
                        submissionForm.articleName = '';
                        submissionForm.articleNotes = '';
                    }
                }

                fetchArticles();
            }
        );

        watch(
            () => submissionForm.articleName,
            (__new, __old) => {
                if (!__new) submissionForm.article = null;
                else {
                    const tmp = fetchedArticleDetails?.value?.data?.find(e => e.article === __new);
                    if (tmp)submissionForm.article = tmp.articleId;
                }

                submissionForm.articleNotes = '';
            }
        );

        watch(
            () => submissionForm.genLob,
            (__new, __old) => {
                dropDownOption.lineOfBusinessOptions.forEach(lookUp => {
                    if (lookUp.name === submissionForm.genLob) {
                        submissionLookUpParamKey = lookUp.submissionLookupKey;
                    }
                });

                if (notifiersEnabled.value) {
                    if (!__new || __new !== __old) {
                        submissionForm.genCategory = '';
                        submissionForm.genType = '';
                        dropDownOption.genericCategoryOptions = [];
                        dropDownOption.typeOptions = [];
                    }
                }
            }
        );

        const __shortArticleDesc = ref('');
        if (submissionForm?.shortArticleDescription !== submissionForm?.styleGuideName?.toUpperCase()) {
            if (submissionForm.styleGuideName === null) {
                __shortArticleDesc.value = submissionForm?.shortArticleDescription?.replaceAll(`${submissionForm?.styleGuideName?.toUpperCase() ?? submissionForm?.submissionTypeName?.toUpperCase()} - `, '');
            } else {
                __shortArticleDesc.value = submissionForm.shortArticleDescription.replaceAll(`${submissionForm.styleGuideName.toUpperCase()} - `, '');
            }
        }
        
        watch(
            () => [__shortArticleDesc.value, submissionForm.styleGuideName],
            () => {
                if (submissionForm.styleGuideName === null) {
                    submissionForm.shortArticleDescription = (`${submissionForm?.styleGuideName ?? submissionForm?.submissionTypeName} ` +
                  `${__shortArticleDesc.value ? ` - ${__shortArticleDesc.value}` : ''}`)?.toUpperCase();
                } else {
                    submissionForm.shortArticleDescription = (`${submissionForm.styleGuideName}` +
                    `${__shortArticleDesc.value ? ` - ${__shortArticleDesc.value}` : ''}`).toUpperCase();
                }
            }, { deep: true, immediate: true }
        );

        watch(
            () => [
                __shortArticleDesc.value, submissionForm.longArticleDescription, submissionForm.category,
                submissionForm.articleName, submissionForm.materialsSupplied, submissionForm.safetyDocIncluded, submissionForm.physicalSampleIncluded,
                submissionForm.licenseeReqStage, submissionForm.onShelfDate, submissionForm.podArtReferenceNumber,
                submissionForm.finalInventory, submissionForm.contractNumber, submissionForm.genCategory, submissionForm.genLob,
                submissionForm.genType, submissionForm.gameCode, submissionForm.assetType,
                submissionForm.pressReleaseCategory, submissionForm.opaId
            ],
            () => {
                submissionFormValidations.shortArticleDescription = !__shortArticleDesc.value || __shortArticleDesc.value.length > 256 ? REQUIRED_FIELD : '';
                submissionFormValidations.longArticleDescription = !submissionForm.longArticleDescription || submissionForm.longArticleDescription.length > 1024 ? REQUIRED_FIELD : '';
                submissionFormValidations.category = !submissionForm.category && categoryFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.article = !submissionForm.articleName && articleFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.materialsSupplied = !submissionForm.materialsSupplied && materialSuppliedFieldState.value === REQUIRED ? REQUIRED_FIELD : '';

                submissionFormValidations.safetyDocIncluded = !submissionForm.safetyDocIncluded && safetyDocFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                // submissionFormValidations.physicalSampleIncluded = !submissionForm.physicalSampleIncluded && sampleIncludedFieldState.value === REQUIRED ? REQUIRED_FIELD : '';

                if (submissionForm.submissionTypeName !== OPA_SUBMISSION_TYPE) {
                    submissionFormValidations.physicalSampleIncluded = submissionForm.physicalSampleIncluded === 'No' && sampleIncludedFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                } else {
                    submissionFormValidations.physicalSampleIncluded = !submissionForm.physicalSampleIncluded && sampleIncludedFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                }

                submissionFormValidations.licenseeReqStage = !submissionForm.licenseeReqStage && submittedItemForReviewFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.podArtReferenceNumber = !submissionForm.podArtReferenceNumber && podArtReferenceNumberFieldState.value === REQUIRED ? REQUIRED_FIELD : '';

                submissionFormValidations.onShelfDate = !submissionForm.onShelfDate && onShelfDateFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.finalInventory = !submissionForm.finalInventory && finalInventoryFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.contractNumber = !submissionForm.contractNumber && contractNumberFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.genLob = !submissionForm.genLob && genLineOfBusinessFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.genCategory = !submissionForm.genCategory && genCategoryFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.genType = !submissionForm.genType && genTypeFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                if (submissionFormValidations.genLob === REQUIRED_FIELD && !submissionForm.genCategory) {
                    submissionFormValidations.genCategory = SELECT_LINE_OF_BUSINESS;
                    submissionFormValidations.genType = SELECT_LINE_OF_BUSINESS;
                }

                submissionFormValidations.gameCode = !submissionForm.gameCode && gameCodeFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.assetType = !submissionForm.assetType && gameCodeFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.pressReleaseCategory = !submissionForm.pressReleaseCategory && pressReleaseCategoryFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
                submissionFormValidations.opaId = !submissionForm.opaId && opaNumberFieldState.value === REQUIRED ? REQUIRED_FIELD : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        // submission details logic
        const submissionDate = computed(() => formatDate(submissionForm.creationDate));

        const fetchedLicenseeContractDetails = computed(() => store.getters['baLicensee/getLicenseeContractDetailsList']);

        const fetchLicenseeDetails = async () => {
            try {
                await store.dispatch('baLicensee/fetchDrmLicenseeContractDetails', {
                    id: submissionForm.contractNumber
                });
                submissionForm.contractStatus = fetchedLicenseeContractDetails.value.contractStatus;
                if (submissionForm.contractStatus === 'TERMINATED') {
                    submissionForm.contractStatus = 'EXPIRED';
                }
            } catch (err) {
                console.error(err);
            }
        };

        const fetchAllSubmissionDropdownOptions = async () => {
            try {
                // fetch dropdown options if respective field is not invisible
                const dropdownPromiseStack = [];
                if (gameCodeFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchGameCodes());
                }
                if (assetTypeFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchDropDownOptions(ASSET_TYPE_DROPDOWN_KEY));
                }
                if (categoryFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchArticlesCategory(CATEGORY_DROPDOWN_KEY));
                }
                if (pressReleaseCategoryFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchDropDownOptions(PRESS_RELEASE_DROPDOWN_KEY));
                }
                if (materialSuppliedFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchDropDownOptions(MATERIAL_SUPPLIED_DROPDOWN_KEY));
                }
                if (genLineOfBusinessFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchDropDownOptions(LINE_OF_BUSINESS_DROPDOWN_KEY));
                }
                if (genCategoryFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchDropDownOptions(GENERIC_CATEGORY_DROPDOWN_KEY));
                }
                if (genTypeFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchDropDownOptions(TYPE_OPTIONS_DROPDOWN_KEY));
                }
                if (submittedItemForReviewFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchItemForReview());
                }
                if (packagingSubmissionNumberFieldState.value !== INVISIBLE) {
                    dropdownPromiseStack.push(fetchPackagingSubmissionNumber());
                }
                if (dropdownPromiseStack.length) {
                    await Promise.all(dropdownPromiseStack);
                }
            } catch (err) {
                console.error(err);
            }
        };

        const showGameCodeDropDown = ref('N');
        const handlGameCodeSelection = (e) => {
            showGameCodeDropDown.value = e;
        };
        const gameCodeDetailsLoading = ref(false);
        const fetchedGameCodeDetails = computed(() => store.getters['baSubmissions/getNewGameCodes']);
        const gameCodeOptions = computed(() => fetchedGameCodeDetails?.value?.length > 0 ? fetchedGameCodeDetails.value : ['']);
        const fetchGameCodes = async () => {
            try {
                gameCodeDetailsLoading.value = true;
                await store.dispatch('baSubmissions/fetchMasterDataSubmissionGameCodes', {
                    params: {
                        licenseeName: submissionForm.licenseeName ? submissionForm.licenseeName : dealSummaryData.licenseeName
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                gameCodeDetailsLoading.value = false;
            }
        };

        const urlContractNumber = computed(() => submissionForm && submissionForm.contractNumber ? submissionForm.contractNumber : dealSummaryData.contractNumber);
        const fetchArticlesCategory = async (dropDownKeyName) => {
            try {
                articleDetailsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmArticlesDetails', {
                    params: {
                        page: 1,
                        limit: 5000
                    },
                    id: urlContractNumber.value
                });
                dropDownOption[dropDownKeyName] = fetchedArticleDetails.value.data;
            } catch (err) {
                console.error(err);
            } finally {
                articleDetailsLoading.value = false;
            }
        };

        const categoryOptions = computed(() => dropDownOption.categoryOptions ? dropDownOption.categoryOptions.map(art => art.articleCategory) : []);
        const articleOptions = computed(() => fetchedArticleDetails.value && fetchedArticleDetails?.value?.data && fetchedArticleDetails?.value?.data?.length > 0 ? fetchedArticleDetails.value?.data.map(art => art.article) : []);
        const articleNotesOptions = computed(() => {
            const tmp = fetchedArticleDetails?.value?.data?.filter(note => submissionForm.articleName === note.article && note.articleNote)
                .map(note => note.articleNote) || [];
            if (tmp && tmp.length) submissionForm.articleNotes = tmp[0];
            return tmp;
        });

        onMounted(async () => {
            await fetchArticles();
        });

        const fetchedArticleDetails = computed(() => store.getters['baLicensee/getArticleContractList']);
        const articleDetailsLoading = ref(false);
        const fetchArticles = async (dropDownKeyName) => {
            try {
                articleDetailsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmArticlesDetails', {
                    params: {
                        articleCategory: submissionForm.category,
                        page: 1,
                        limit: 5000
                    },
                    id: urlContractNumber.value
                });
            } catch (err) {
                console.error(err);
            } finally {
                articleDetailsLoading.value = false;
            }
        };

        const typeOptions = computed(() => dropDownOption.typeOptions ? dropDownOption.typeOptions.map(type => type.name) : []);
        const lineOfBusinessOptions = computed(() => dropDownOption.lineOfBusinessOptions ? dropDownOption.lineOfBusinessOptions.map(line => line.name) : []);
        const genericCategoryOptions = computed(() => dropDownOption.genericCategoryOptions ? dropDownOption.genericCategoryOptions.map(genCat => genCat.name) : []);
        const materialSuppliedOptions = computed(() => dropDownOption.materialSuppliedOptions ? dropDownOption.materialSuppliedOptions.map(supply => supply.name) : []);
        const pressReleaseCategoryOptions = computed(() => dropDownOption.pressReleaseCategoryOptions ? dropDownOption.pressReleaseCategoryOptions.map(release => release.name) : []);

        const assetTypeOptions = computed(() => dropDownOption.assetTypeOptions ? dropDownOption.assetTypeOptions.map(asset => asset.name) : []);
        const dropDownOption = reactive({
            typeOptions: [],
            articleOptions: [],
            categoryOptions: [],
            assetTypeOptions: [],
            articleNotesOptions: [],
            lineOfBusinessOptions: [],
            genericCategoryOptions: [],
            materialSuppliedOptions: [],
            pressReleaseCategoryOptions: []
        });

        let submissionLookUpParamKey;

        const metarialSuppliedDetailsLoading = ref(false);
        const fetchDropDownOptions = async (dropDownKeyName) => {
            try {
                if (dropDownOption[dropDownKeyName].length > 0) {
                    return;
                }
                metarialSuppliedDetailsLoading.value = true;
                let params;
                if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && dropDownKeyName === MATERIAL_SUPPLIED_DROPDOWN_KEY) {
                    params = {
                        type: MATERIALS_TYPE_PARAM_KEY,
                        code: STD_MATERIALS_CODE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE && dropDownKeyName === MATERIAL_SUPPLIED_DROPDOWN_KEY) {
                    params = {
                        type: MATERIALS_TYPE_PARAM_KEY,
                        code: INT_MATERIALS_CODE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && dropDownKeyName === MATERIAL_SUPPLIED_DROPDOWN_KEY) {
                    params = {
                        type: MATERIALS_TYPE_PARAM_KEY,
                        code: GEN_MATERIALS_CODE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && dropDownKeyName === LINE_OF_BUSINESS_DROPDOWN_KEY) {
                    params = {
                        type: GEN_LOB_TYPE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && dropDownKeyName === GENERIC_CATEGORY_DROPDOWN_KEY) {
                    params = {
                        type: GEN_CAT_TYPE_PARAM_KEY,
                        parentLookupKey: submissionLookUpParamKey,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE && dropDownKeyName === TYPE_OPTIONS_DROPDOWN_KEY) {
                    params = {
                        type: GEN_TYPE_PARAM_KEY,
                        parentLookupKey: submissionLookUpParamKey,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === OPA_SUBMISSION_TYPE && dropDownKeyName === MATERIAL_SUPPLIED_DROPDOWN_KEY) {
                    params = {
                        type: MATERIALS_TYPE_PARAM_KEY,
                        code: OPA_CODE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === PRINT_ON_DEMAND_SUBMISSION_TYPE && dropDownKeyName === MATERIAL_SUPPLIED_DROPDOWN_KEY) {
                    params = {
                        type: MATERIALS_TYPE_PARAM_KEY,
                        code: POD_CODE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === PRESS_RELEASE_SUBMISSION_TYPE && dropDownKeyName === PRESS_RELEASE_DROPDOWN_KEY) {
                    params = {
                        type: CAT_TYPE_PARAM_KEY,
                        code: PRESS_CATEGORY_CODE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }
                if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE && dropDownKeyName === ASSET_TYPE_DROPDOWN_KEY) {
                    params = {
                        type: ASSET_TYPE_PARAM_KEY,
                        page: 1,
                        limit: 5000
                    };
                }

                dropDownOption[dropDownKeyName] = await store.dispatch('baSubmissions/fetchMasterDataOptions', {
                    params: params
                });
            } catch (err) {
                console.error(err);
            } finally {
                metarialSuppliedDetailsLoading.value = false;
            }
        };
        const itemForReviewOptions = computed(() => {
            const tmpStages = store.getters['baSubmissions/getLicenseeRequestedStage'];
            return tmpStages && tmpStages.length ? tmpStages.map(stage => stage.stageName) : [];
        });
        const stageOptionsLoading = ref(false);
        const fetchItemForReview = async () => {
            try {
                if (itemForReviewOptions.value.length > 0 || stageOptionsLoading.value) {
                    return;
                }
                stageOptionsLoading.value = true;
                await store.dispatch('baSubmissions/masterDataLicenseeRequestedStage', {
                    params: {
                        isActive: 'Y',
                        page: 1,
                        limit: 5000
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                stageOptionsLoading.value = false;
            }
        };

        const packagingSubmissionNumberOptions = computed(() => store.getters['baSubmissions/getPackagingSubmissions']);
        const fetchPackagingSubmissionNumber = async () => {
            try {
                if (!submissionForm.licenseeId || !submissionForm.submissionTypeName || !submissionForm.materialsSupplied) return;

                if (stageOptionsLoading.value) return;
                stageOptionsLoading.value = true;

                await store.dispatch('baSubmissions/fetchPackagingSubmissionNumber', {
                    licenseeId: submissionForm.licenseeId,
                    submissionType: GENERIC_SUBMISSION_TYPE,
                    materialSupplied: 'Packaging for multiple SKUs',
                    page: 1,
                    limit: 5000
                });
            } catch (err) {
                console.error(err);
            } finally {
                stageOptionsLoading.value = false;
            }
        };

        const sampleIncludedOptions = ref([
            'Yes',
            'No'
        ]);

        const safetyDocumentsIncludedOptions = ref([
            'Yes',
            'No'
        ]);

        const finalInventoryOptions = ref([
            'Yes',
            'No'
        ]);

        // press release template download logic
        const downloadPressReleaseTemplate = () => {
            const windowOpened = window.open('/Marvel_PressRelease_Template.docx', '_blank');
            if (!windowOpened) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                });
            } else {
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: ATTACHEMENT_DOWNLOADED_SUCCESSFULLY
                });
            }
        };

        // contract modal logic
        const contractModalVisibility = ref(false);

        const setContractModalVisibility = (visibility) => {
            // if (visibility === true && (!submissionForm.contractNumber || !submissionForm.article || !submissionForm.styleGuideId ) ){
            //   notificationsStack.value.push({
            //     type: WARNING,
            //     message: 'Please ensure Article and Style Guide are specified to change the Contract.'
            //   })
            // }
            // else
            contractModalVisibility.value = visibility;
        };

        // deal summary logic
        const isDealSummaryModalVisible = ref(false);

        const setDealSummaryModalVisibility = (isModalVisible) => {
            isDealSummaryModalVisible.value = isModalVisible;
        };

        const openDealSummary = () => {
            dealSummaryData.contractNumber = submissionForm.contractNumber;
            dealSummaryData.licenseeId = submissionForm.licenseeId;
            setDealSummaryModalVisibility(true);
        };

        onMounted(async () => {
            if (submissionForm?.submissionTypeName === OPA_SUBMISSION_TYPE) {
                submissionForm.physicalSampleIncluded = 'Yes';
                sampleIncludedOptions.value = ['Yes'];
            }

            await fetchAllSubmissionDropdownOptions();
            await fetchLicenseeDetails();
        });

        return {
            // submission details validations
            REQUIRED,
            VIEW_ONLY,
            INVISIBLE,
            PRESS_RELEASE_SUBMISSION_TYPE,
            submissionFormValidations,
            safetyDocFieldState,
            contractNumberFieldState,
            contractStatusFieldState,
            categoryFieldState,
            articleFieldState,
            articleNotesFieldState,
            genCategoryFieldState,
            genLineOfBusinessFieldState,
            genTypeFieldState,
            sampleIncludedFieldState,
            onShelfDateFieldState,
            finalInventoryFieldState,
            materialSuppliedFieldState,
            packagingSubmissionNumberFieldState,
            gameCodeFieldState,
            assetTypeFieldState,
            pressReleaseCategoryFieldState,
            legacySubmissionIdFieldState,
            downloadPressReleaseTemplateButtonState,
            opaNumberFieldState,
            submittedItemForReviewFieldState,
            podArtReferenceNumberFieldState,
            sendSampleMessage,

            // submission details
            getDistinctArray,
            submissionForm,
            submissionDate,
            gameCodeOptions,
            fetchGameCodes,
            assetTypeOptions,
            categoryOptions,
            articleOptions,
            fetchArticles,
            articleNotesOptions,
            materialSuppliedOptions,
            fetchDropDownOptions,
            itemForReviewOptions,
            fetchItemForReview,
            packagingSubmissionNumberOptions,
            fetchPackagingSubmissionNumber,
            sampleIncludedOptions,
            safetyDocumentsIncludedOptions,
            finalInventoryOptions,
            lineOfBusinessOptions,
            genericCategoryOptions,
            typeOptions,
            pressReleaseCategoryOptions,
            downloadPressReleaseTemplate,
            // contract modal
            contractModalVisibility,
            setContractModalVisibility,
            // deal summary
            isDealSummaryModalVisible,
            setDealSummaryModalVisibility,
            openDealSummary,
            upperCaseInputDescriptions,
            fetchArticlesCategory,
            submissionFormMode,
            __shortArticleDesc,
            showGameCodeDropDown,
            handlGameCodeSelection
        };
    }
};
</script>
